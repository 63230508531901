<!--
 * @Author: mengxiangfa
 * @Date: 2021-04-20 13:43:26
 * @LastEditors: mengxiangfa
 * @LastEditTime: 2021-11-05 13:45:46
 * @Description: file content
 * @FilePath: \DDDgClient\src\views\home.vue
-->
<template>
  <div class="home-box">
    <div class="header">
      <div class="title" @click="showPicker = true">
        {{ projectName
        }}<van-icon :name="showPicker === true ? 'arrow-up' : 'arrow-down'" />
      </div>
      <van-popup v-model="showPicker" round position="bottom">
        <van-picker
          show-toolbar
          :columns="proejctList"
          :default-index="defaultIndex"
          value-key="projectName"
          visible-item-count="3"
          swipe-duration="300"
          ref="picker"
        >
          <template slot="default">
            <div class="picker-title">请选择项目</div>
          </template>
          <template slot="columns-bottom">
            <van-button class="picker-btn" @click="selectProject"
              >下一步</van-button
            >
          </template>
        </van-picker>
      </van-popup>
    </div>
    <div class="menus">
      <div class="menu" @click="selectNext(1)">
        <div class="icon"><img :src="ICON1" alt="" /></div>
        <div class="text">位置设置</div>
      </div>
      <div class="menu" @click="selectNext(2)">
        <div class="icon"><img :src="ICON2" alt="" /></div>
        <div class="text">模块更换</div>
      </div>
      <div class="menu" @click="selectNext(3)">
        <div class="icon"><img :src="ICON3" alt="" /></div>
        <div class="text">开关更换</div>
      </div>
      <!-- <button @click="$router.push('/test')">测试</button> -->
    </div>
    <van-action-sheet v-model="typeShow" title="请选择" :closeable="false">
      <div class="content">
        <van-button class="next-btn" @click="wxSweep">扫一扫</van-button>
        <van-button class="next-btn" @click="manual">手动获取</van-button>
      </div>
    </van-action-sheet>
    <van-overlay :show="loading" z-index="1999" class-name="loading-overlay">
      <div class="wrapper">
        <div class="block">
          <van-loading />
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import "../style/style.scss"
import Cookies from "js-cookie"
import wx from "weixin-js-sdk" //引入微信js-sdk
import { getProjects } from "../request/api"
import { Toast } from "vant"
export default {
  name: "home",
  data() {
    return {
      ICON1: require("../assets/icons/location.png"),
      ICON2: require("../assets/icons/module.png"),
      ICON3: require("../assets/icons/breaker.png"),
      showPicker: false,
      typeShow: false,
      loading: false,
      projectId: "",
      projectName: "请选择项目",
      defaultIndex: "",
      type: "", //1位置上传 2 模块更换 3 开关更换
      proejctList: [],
    }
  },
  watch: {},
  created() {
    this.projectId = Cookies.get("projectId") || ""
    this.getProjects()
    this.getWeiXinConfig()
  },
  mounted() {},
  methods: {
    getWeiXinConfig() {
      let url = window.location.href.split("#")[0]
      // console.log(window.location.href.split("#")[0], "1")
      // let url = encodeURIComponent(location.href.split('#')[0])
      this.$getWXConfig(url, ["scanQRCode", "showModal"])
    },
    getProjects() {
      this.loading = true
      getProjects({})
        .then(res => {
          this.proejctList = res.data.items
          // console.log(res)
          setTimeout(() => {
            this.loading = false
          }, 300)
          setTimeout(() => {
            if (this.projectId) {
              this.proejctList.forEach((item, index) => {
                if (item.projectId === this.projectId) {
                  this.projectName = item.projectName
                  this.defaultIndex = index
                }
              })
            } else {
              this.showPicker = true
            }
          }, 500)
        })
        .catch(err => {
          setTimeout(() => {
            this.loading = false
          }, 500)
          console.log(err)
        })
    },
    //选择项目确定
    selectProject() {
      let obj = this.$refs.picker.getValues()[0]
      this.projectId = obj.projectId
      this.projectName = obj.projectName
      Cookies.set("projectId", obj.projectId)
      this.showPicker = false
    },
    //下一步
    selectNext(val) {
      this.type = val
      if (val === 1) {
        this.typeShow = true
      } else if (val === 2) {
        this.wxSweep()
      } else {
        this.typeShow = true
      }
    },
    //微信扫一扫
    wxSweep() {
      let that = this
      wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["barCode"], //'qrCode', 可以指定扫二维码还是一维码，默认二者都有
        success: function(res) {
          var result = res.resultStr // 当needResult 为 1 时，扫码返回的结果
          let arr = result.split(",")
          let code = arr[arr.length - 1]
          if (!code || !/^[0-9a-zA-Z]*$/g.test(code)) {
            Toast("请扫描正确的模块条形码")
            return
          } else {
            if (that.type === 1) {
              //位置上传
              that.$router.push({
                path: "/updateLocation",
                query: {
                  projectId: that.projectId,
                  type: 1,
                  mac: code,
                },
              })
            } else if (that.type === 2) {
              //模块更换
              that.$router.push({
                path: "/changeModule",
                query: {
                  projectId: that.projectId,
                  type: 1,
                  mac: code,
                },
              })
            } else {
              //开关更换
              that.$router.push({
                path: "/changeSwitch",
                query: {
                  projectId: that.projectId,
                  type: 1,
                  mac: code,
                },
              })
            }
          }
        },
      })
    },
    //手动获取
    manual() {
      if (this.type === 1) {
        this.$router.push({
          path: "/updateLocation",
          query: {
            projectId: this.projectId,
            type: 2,
          },
        })
      } else if (this.type === 3) {
        this.$router.push({
          path: "/changeSwitch",
          query: {
            projectId: this.projectId,
            type: 2,
          },
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.home-box {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 30px;
    width: 100%;
    height: 90px;
    background-color: #fff;
    z-index: 999;
    .title {
      margin: 0 auto;
      // width: 160px;
      text-align: center;
      ::v-deep .van-icon {
        vertical-align: -2px;
      }
    }
    ::v-deep .van-picker__toolbar {
      height: unset;
    }
    ::v-deep .van-picker-column__item {
      font-size: 24px;
    }
    .picker-title {
      margin: 10px auto;
      font-size: 28px;
      color: #333333;
    }
    .picker-btn {
      margin: 0 55px 55px 55px;
      width: 640px;
      height: 70px;
      font-size: 24px;
      color: #333333;
      background: #ffffff;
      border: 2px solid #eaeaea;
      border-radius: 35px;
    }
  }
  .menus {
    padding-top: 200px;
    .menu {
      margin: 0 auto;
      margin-bottom: 30px;
      width: 180px;
      .icon {
        position: relative;
        width: 100%;
        height: 180px;
        background: #ffffff;
        border: 1px solid #eaeaea;
        box-shadow: 0px 4px 15px 5px rgba(203, 203, 203, 0.22);
        border-radius: 20px;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 80px;
        }
      }
      .text {
        line-height: 50px;
        text-align: center;
      }
    }
  }
  ::v-deep .van-action-sheet__header {
    padding-top: 40px;
    padding-bottom: 20px;
    font-size: 28px;
    color: #333;
  }
  .content {
    padding-bottom: 20px;
    .next-btn {
      margin: 15px 55px;
      width: 640px;
      height: 70px;
      font-size: 24px;
      color: #333333;
      background: #ffffff;
      border: 2px solid #eaeaea;
      border-radius: 35px;
    }
  }
}
.loading-overlay {
  background-color: rgba(0, 0, 0, 0);
}
</style>
